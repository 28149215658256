// @ts-nocheck
import React from "react";
import Header from "./components/Header/Header.js";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomeScreen from "./screens/HomeScreen.js";
import Footer from "components/Footer/Footer.js";
import CookstoveProjectScreen from "screens/CookstoveProjectScreen.js";
import ContactUsScreen from "screens/ContactUsScreen.js";
import AboutUsScreen from "screens/AboutUsScreen.js";

function App() {
  return (
    <BrowserRouter>
      <Header />
      <main>
        <Routes>
          {/* Wrap the Route components inside a Routes element */}
          <Route
            path="/"
            element={<HomeScreen />}
          />
          <Route
            path="/AboutUs"
            element={<AboutUsScreen />}
          />
          <Route
            path="/CookstoveProject"
            element={<CookstoveProjectScreen />}
          />
          <Route
            path="/ContactUs"
            element={<ContactUsScreen />}
          />
          {/* Add other routes here */}
        </Routes>
      </main>
      <Footer></Footer>
    </BrowserRouter>
  );
}

export default App;
