import React, { useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
// import "components/Common/";

export default function ContactUsScreen() {
  // State variables to manage form input
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here (e.g., send data to API)
    console.log(formData);
  };

  return (
    <div className="contact-us">
      <Container
        fluid
        className="contact-us-banner"
      >
        <h1 className="contact-us-title">Contact Us</h1>
        <p className="contact-us-subtitle">
          We're here to help and answer any question you might have. We look
          forward to hearing from you.
        </p>
      </Container>

      <Container className="contact-us-form-section">
        <Row>
          <Col md={6}>
            <h3>Get in Touch</h3>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formName">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  placeholder="Enter your full name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group
                controlId="formPhone"
                className="mt-3"
              >
                <Form.Label>Phone</Form.Label>
                <Form.Control
                  type="tel"
                  name="phone"
                  placeholder="Enter your phone number"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group
                controlId="formEmail"
                className="mt-3"
              >
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  placeholder="Enter your email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group
                controlId="formMessage"
                className="mt-3"
              >
                <Form.Label>Message</Form.Label>
                <Form.Control
                  as="textarea"
                  name="message"
                  rows={4}
                  placeholder="Enter your message"
                  value={formData.message}
                  onChange={handleChange}
                />
              </Form.Group>
              <Button
                variant="primary"
                type="submit"
                className="mt-4"
              >
                Submit
              </Button>
            </Form>
          </Col>

          <Col
            md={6}
            className="contact-details"
          >
            <h3>Our Contact Information</h3>
            <p>
              If you would prefer to reach out to us directly, you can use the
              contact details below:
            </p>
            <ul>
              <li>
                <strong>Phone:</strong> +123 456 7890
              </li>
              <li>
                <strong>Email:</strong> info@alphabetgreens.com
              </li>
              <li>
                <strong>Address:</strong> 1234 Green St, Sustainability City,
                56789
              </li>
            </ul>
            <h4>Business Hours</h4>
            <p>Monday - Friday: 9:00 AM - 6:00 PM</p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
