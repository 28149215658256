// @ts-nocheck
import React from "react";

import { Col, Row } from "react-bootstrap";
import "./VerificationStyles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import Carbon from "../../assets/logo/gsLogo.png";
import { Link } from "react-router-dom";
import Summary from "../../assets/docs/summary.pdf";
import Grievance from "../../assets/docs/Grievance.pdf";
import AnnouncementOfDesign from "../../assets/docs/AnnouncementOfDesign.pdf";
import AnnouncementOfLocal from "../../assets/docs/AnnoucementOfLocalStakeholderMeetingNotice.pdf";
import StakeholderConsultationReport from "../../assets/docs/StakeholderConsultationReport.pdf";
import DesignConsultationReport from "../../assets/docs/DesignConsultationReport.pdf"

export default function Verification(props) {
  return (
    <div className="Verification">
      <Row className="Verification-container">
        <Col
          className="Verification-header"
          lg="6"
          md="12"
        >
          <h2>
            All our projects are certified by the highest available voluntary
            carbon standards
          </h2>
        </Col>
        <Col
          className="Verification-cards"
          lg="6"
          md="12"
        >
          <Row className="mb-5">
            <Col
              lg="6"
              md="12"
            >
              <img
                src={Carbon}
                alt=""
              />
              <hr />
              <p>
                The Programme of Activity (PoA) is registered under Gold
                Standard Mechanism with GS id:12494 and its first Real Case VPA
                with GS id: 12495.
              </p>
            </Col>
            <Col>
              <Row>
                <Col className="d-flex flex-column">
                  <FontAwesomeIcon icon={icon({ name: "file-pdf" })} />
                  <Link
                    to={Grievance}
                    rel="noopener noreferrer"
                    target="_blank"
                    className="verificationDownloads"
                  >
                    Grievance Mechanism
                  </Link>
                </Col>
                <Col className="d-flex flex-column">
                  <FontAwesomeIcon icon={icon({ name: "file-pdf" })} />
                  <Link
                    to={Summary}
                    rel="noopener noreferrer"
                    target="_blank"
                    className="verificationDownloads"
                  >
                    Non Technical Summary of Project
                  </Link>
                </Col>
                <Col className="d-flex flex-column">
                  <FontAwesomeIcon icon={icon({ name: "file-pdf" })} />
                  <Link
                    to={AnnouncementOfLocal}
                    rel="noopener noreferrer"
                    target="_blank"
                    className="verificationDownloads"
                  >
                    Announcement of Local Stakeholder Consultation
                  </Link>
                </Col>
                <Col className="d-flex flex-column">
                  <FontAwesomeIcon icon={icon({ name: "file-pdf" })} />
                  <Link
                    to={AnnouncementOfDesign}
                    rel="noopener noreferrer"
                    target="_blank"
                    className="verificationDownloads"
                  >
                    Announcement of Design Consultation Review
                  </Link>
                </Col>
                <Col className="d-flex flex-column">
                  <FontAwesomeIcon icon={icon({ name: "file-pdf" })} />
                  <Link
                    to={StakeholderConsultationReport}
                    rel="noopener noreferrer"
                    target="_blank"
                    className="verificationDownloads"
                  >
                    Stakeholder Consultation Report
                  </Link>
                </Col>
                <Col className="d-flex flex-column">
                  <FontAwesomeIcon icon={icon({ name: "file-pdf" })} />
                  <Link
                    to={DesignConsultationReport}
                    rel="noopener noreferrer"
                    target="_blank"
                    className="verificationDownloads"
                  >
                    Design Consultation Report
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
