// @ts-nocheck
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Nav,
  Navbar,
  NavDropdown,
  Offcanvas,
  Row,
  Col,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import Logo from "../../assets/logo/logo.svg";
import "./HeaderStyles.css"; // Ensure styles are applied

// Project images
import CookStoveProject from "../../assets/photos/cookstove-project.jpeg";
import LedProject from "../../assets/photos/led-project.avif";
import FoodDigesterProject from "../../assets/photos/food-digester-project.jpeg";
import SolarProject from "../../assets/photos/solar-project.jpeg";
import CarbonOffset from "../../assets/photos/Carbon-Offset.jpeg";
import CarbonFootprinting from "../../assets/photos/carbon-footprinting.jpeg";

export default function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [hoveredImage, setHoveredImage] = useState(Logo); // Default image

  useEffect(() => {
    document.body.style.overflow = isOpen ? "hidden" : "unset";
  }, [isOpen]);

  useEffect(() => {
    const dropdownMenu = document.querySelector(".dropdown-menu");
    if (dropdownMenu) {
      dropdownMenu.style.animation = isClosing ? "slide-out 500ms" : "";
    }
  }, [isClosing]);

  const handleToggle = () => {
    if (isOpen) {
      setIsClosing(true);
      setTimeout(() => {
        setIsOpen(false);
        setIsClosing(false);
      }, 500); // Match animation duration
    } else {
      setIsOpen(true);
    }
  };

  const handleHover = (image) => {
    setHoveredImage(image);
  };

  const handleMouseLeave = () => {
    setHoveredImage(Logo); // Revert to default logo on mouse leave
  };

  return (
    <>
      <Navbar
        expand="md"
        className="header"
      >
        <Container fluid>
          <Link to="/">
            <img
              className="navbar-logo"
              src={Logo}
              alt="Alphabet Greens Logo"
            />
          </Link>
          <Navbar.Brand href="/">Alphabet Greens</Navbar.Brand>
          <Navbar.Toggle aria-controls="offcanvasNavbar-expand-md" />
          <Navbar.Offcanvas
            id="offcanvasNavbar-expand-md"
            placement="end"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>Alphabet Greens</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="nav-body flex-grow-1 pe-3">
                <Link to="/">Home</Link>
                <Link to="/AboutUs">About Us</Link>
                <Link to="/Impact">Our Impact</Link>

                <NavDropdown
                  title="What We Do"
                  id="offcanvasNavbarDropdown-expand-md"
                  show={isOpen}
                  onToggle={setIsOpen}
                >
                  <FontAwesomeIcon
                    className="header-close-btn"
                    icon={icon({ name: "x" })}
                    onClick={handleToggle}
                  />
                  <Row className="nav-links">
                    <Col md={4}>
                      <h5>Climate Investments</h5>
                      <ul>
                        <NavDropdown.Item
                          onMouseEnter={() => handleHover(CookStoveProject)}
                          onMouseLeave={handleMouseLeave}
                        >
                          <Link to="/CookstoveProject">
                            The CookStove Project
                          </Link>
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          onMouseEnter={() => handleHover(LedProject)}
                          onMouseLeave={handleMouseLeave}
                        >
                          The LED Project
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          onMouseEnter={() => handleHover(FoodDigesterProject)}
                          onMouseLeave={handleMouseLeave}
                        >
                          The Food Digester Project
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          onMouseEnter={() => handleHover(SolarProject)}
                          onMouseLeave={handleMouseLeave}
                        >
                          The Solar Project
                        </NavDropdown.Item>
                      </ul>
                    </Col>
                    <Col md={4}>
                      <h5>Offset Portfolio Management</h5>
                      <ul>
                        <NavDropdown.Item
                          onMouseEnter={() => handleHover(CarbonOffset)}
                          onMouseLeave={handleMouseLeave}
                        >
                          Carbon Offset
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          onMouseEnter={() => handleHover(CarbonFootprinting)}
                          onMouseLeave={handleMouseLeave}
                        >
                          Carbon Footprinting
                        </NavDropdown.Item>
                      </ul>
                    </Col>
                    <Col md={4}>
                      <h5>Trading Carbon Credits</h5>
                      <ul>
                        <NavDropdown.Item
                          onMouseEnter={() => handleHover(CarbonOffset)}
                          onMouseLeave={handleMouseLeave}
                        >
                          Selling Credits
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          onMouseEnter={() => handleHover(CarbonOffset)}
                          onMouseLeave={handleMouseLeave}
                        >
                          Buying Credits
                        </NavDropdown.Item>
                      </ul>
                    </Col>
                  </Row>
                  <Row
                    className="header-banner"
                    style={{ backgroundImage: `url(${hoveredImage})` }}
                  />
                </NavDropdown>
                <Link to="/ContactUs">Contact Us</Link>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </>
  );
}
